<template>
    <div class="pt-16">
    <div class="p-4 sm:ml-64">
      <div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 bg-white dark:bg-gray-800">

        <!-- Title -->

        <p class="text-3xl/loose font-bold text-black dark:text-white">Side Projects</p>


        <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">


        <!-- Project Cards -->
        <a v-for="project in projects" :key="project" :href="project.link"
        class="hover:bg-slate-200 hover:ring-slate-200 hover:shadow-md group 
                  dark:hover:bg-slate-600 dark:hover:ring-slate-600 dark:hover:shadow-md 
                  rounded-md p-3 
                  bg-white dark:bg-gray-800 
                  ring-1 
                  ring-slate-200 dark:ring-slate-600 
                  shadow-sm dark:shadow-sm
                  min-w-72">
          <a>
              <div>
                <dt class="sr-only">Title</dt>
                <dd class="group-hover:text-black dark:group-hover:text-white font-semibold text-slate-800 dark:text-slate-400">
                  {{ project.title }}
                </dd>
              </div>
              <div>
                <dt class="sr-only">Category</dt>
                <dd class="group-hover:text-black dark:group-hover:text-white text-slate-800 dark:text-slate-400 ">{{project.category}}</dd>
              </div>
              <div>
                  <dt class="sr-only">Users</dt>
                  <dd v-for="author in project.author" :key="author.name" class="inline-flex flex-row justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-1.5">
                    <img :src=author.avatar :alt="project.author.name" class="w-6 h-6 rounded-full bg-slate-100 ring-2 ring-white" loading="lazy">
                  </dd>
              </div>
          </a>
        </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'Project',
    data() {
        return {
            projects: [
                {
                  title: 'Dante Dog LCB',
                  category: 'Web Development',
                  author: [
                    {
                      name: 'xfdxfdxfd',
                      avatar: 'https://github.com/xfdxfdxfd.png'
                    },
                  ],
                  link: 'https://xgaydog.com',
                },
                {
                  title: 'VR Bang Dream!',
                  category: 'VR Game',
                  author: [
                    {
                      name: 'xfdxfdxfd',
                      avatar: 'https://github.com/xfdxfdxfd.png'
                    },
                    {
                      name: 'ZinerX',
                      avatar: 'https://github.com/ZinerX.png'
                    },
                  ],
                  link: 'https://github.com/xfdxfdxfd/VRBangDream',
                },
            ]
        }
    },
    computed: {
    },
    methods: {
    },
    mounted() {
    }
}
</script>