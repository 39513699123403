<template>
<div class="min-h-screen bg-gradient-to-b from-gray-100 to-gray-300 dark:from-slate-950 dark:to-slate-800">
  <div class="fixed w-full">
  <div class="max-w mx-auto bg-white dark:bg-slate-950 border-2 border-b-slate-600 dark:border-x-slate-950 dark:border-t-slate-950 overflow-hidden h-16 flex flex-wrap content-center">
    <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
  </button>
    <a href="https://github.com/xfdxfdxfd" target="_blank" class="pt-2 sm:pt-0">
      <img v-if="theme == 'light'" class="h-8 pl-4 pr-4" src='../src/assets/github-mark.webp'>
      <img v-else class="h-8 pl-4 pr-4" src='../src/assets/github-mark-white.webp'>
    </a>
    <div class="font-semibold text-xl uppercase text-black dark:text-white cursor-default pt-3 sm:pt-0"> xfdxfdxfd </div>
    <div class="absolute right-0 pr-4 pt-4" @click="toggleTheme()">
      <img v-if="theme == 'light'" class="h-8 pl-4 pr-4 cursor-pointer" src='../src/assets/sun.webp'>
      <img v-else class="h-8 pl-4 pr-4 cursor-pointer" src='../src/assets/moon.webp'>
    </div>
  </div></div>

  <aside id="default-sidebar" class="fixed left-0 sm:top-16 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-900">
        <ul class="space-y-2 font-medium">
          <!-- <li v-for="name in ['Home','Intro', 'Open Source Project', 'Contact Me']" :key="name" @click="currentTab=name" role="button"> -->
            <li v-for="name in ['Home', 'Project']" :key="name" @click="currentTab=name" role="button">
              <RouterLink :to="'/'+name">
                <a  class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group">
                  <span class="ms-3">
                {{ name }}
                  </span>
                </a>
              </RouterLink>
            </li>
        </ul>
    </div>
  </aside>

  <RouterView />

</div>
</template>

<script>
import { initFlowbite } from 'flowbite';

export default {
  name: 'App',
  data() {
    return {
      theme:'light',
      currentTab: 'Intro',
    }
  },
  components: {
  },
  created() {
    if (localStorage.theme ==undefined) {
      localStorage.theme = 'light'
    }
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      this.theme="dark";
    } else {
      document.documentElement.classList.remove('dark')
    }
  },
  mounted() {
    initFlowbite();
  },
  methods: {
    toggleTheme() {
      // console.log(localStorage.theme)
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark')
        localStorage.theme = 'light'
        this.theme="light";
      } else {
        document.documentElement.classList.add('dark')
        localStorage.theme = 'dark'
        this.theme="dark";
      }
    }
  }
}
</script>

<style>
</style>
